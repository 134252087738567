
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { FC, useState } from "react"

import { HomeContent } from "@app/components/home"
import { Seo } from "@app/components/seo"
import { Layout } from "@app/shared/components/layout"
import { Meta } from "@app/shared/types/seo"

const abTestCtaVariants = {
  B: "b",
  CONTROL: "control",
} as const

export type AbTestCtaVariant = (typeof abTestCtaVariants)[keyof typeof abTestCtaVariants]
const isAbTestCtaVariant = (value: unknown): value is AbTestCtaVariant => {
  return typeof value === "string" && Object.values(abTestCtaVariants).includes(value as AbTestCtaVariant)
}

const HomeAbtest: FC<InferGetStaticPropsType<typeof wrappedGetStaticProps>> = () => {
  const { t } = useTranslation()
  const metaTags = t<Meta>(`sell-house:meta`, {}, { returnObjects: true })
  const [heroIsVisible, setHeroIsVisible] = useState(true)
  const router = useRouter()
  const { testVariant } = router.query

  return (
    <>
      <Seo
        norobots
        description={metaTags.description}
        og={{
          description: metaTags.description,
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          site_name: "Casavo",
        }}
        title={metaTags.title}
      />
      <Layout bottomSpacer accentColor={heroIsVisible ? "orange" : undefined}>
        <HomeContent
          abTestVariant={isAbTestCtaVariant(testVariant) ? testVariant : abTestCtaVariants.CONTROL}
          heroIsVisible={heroIsVisible}
          updateHeroVisibility={setHeroIsVisible}
        />
      </Layout>
    </>
  )
}

const wrappedGetStaticProps = async (ctx: GetStaticPropsContext) => {
  const { testVariant } = ctx.params as { testVariant: AbTestCtaVariant }

  if (isAbTestCtaVariant(testVariant) && ["it"].includes(ctx.locale || "")) {
    return {
      props: {},
      revalidate: process.env.NEXT_TARGET_MODE !== "serverless" ? 86400 : undefined,
    }
  }

  return {
    notFound: true,
    props: {} as never,
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

export const getStaticPaths: GetStaticPaths = () => {
  return {
    fallback: "blocking",
    paths: [
      { locale: "it", params: { testVariant: "control" } },
      { locale: "it", params: { testVariant: "b" } },
    ],
  }
}

export default HomeAbtest


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/home-social/[testVariant]',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  